function highlightTextSnippets(htmlString: string) {
  const regex = /\[START_HIGHLIGHT](.*?)\[END_HIGHLIGHT]/gis;
  const resultMatchGroup = htmlString.match(regex);

  const textSnippets = resultMatchGroup?.map((match) =>
    match.replace(regex, "$1")
  );

  const excludeDivId: string = "chat-container"; // The id of the div you want the walker to avoid

  const filterFunction: NodeFilter = {
    acceptNode: (node: Node) => {
      let currentNode: Node | null = node.parentNode;
      while (currentNode) {
        if (
          currentNode.nodeType === Node.ELEMENT_NODE &&
          (currentNode as Element).id === excludeDivId
        ) {
          return NodeFilter.FILTER_SKIP;
        }
        currentNode = currentNode.parentNode;
      }
      return NodeFilter.FILTER_ACCEPT;
    },
  };

  const walker: TreeWalker = document.createTreeWalker(
    document.body,
    NodeFilter.SHOW_TEXT,
    filterFunction as any
  );

  let node: Node | null;
  let snippetIndex = 0;
  const snippetsLength = textSnippets ? textSnippets.length : 0;

  while ((node = walker.nextNode()) !== null && snippetIndex < snippetsLength) {
    const snippet = textSnippets![snippetIndex];
    const index = node.textContent!.indexOf(snippet);
    if (index !== -1) {
      const before = node.textContent!.substring(0, index);
      const after = node.textContent!.substring(index + snippet.length);

      const span = document.createElement("span");
      span.textContent = snippet;
      span.style.backgroundColor = "yellow";
      span.style.color = "black";
      span.classList.add("highlighted-text");

      const parent = node.parentNode!;
      parent.insertBefore(document.createTextNode(before), node);
      parent.insertBefore(span, node);
      node.textContent = after;

      // Move to the next snippet
      snippetIndex++;
    }
  }
}

export function highlightText(
  highlightHTML: string,
  shouldScroll: boolean = true
): boolean {
  // this function returns true if the text was found and highlighted
  // and false if the text was not found
  try {
    const htmlWithTokens: string = highlightHTML;
    if (htmlWithTokens) {
      highlightTextSnippets(htmlWithTokens);

      var scrollElement = document.querySelector(
        ".highlighted-text"
      ) as HTMLElement;

      if (scrollElement) {
        if (shouldScroll) {
          scrollElement.scrollIntoView({ behavior: "smooth", block: "center" });
          return true;
        }
      } else {
        console.log("highlighted-text scrollElement not found");
      }
    }
  } catch (error) {
    console.log("highlightText error: ", error);
  }
  return false;
}

export function removeHighlight(): Promise<void> {
  return new Promise((resolve) => {
    const highlightedElements: NodeListOf<HTMLElement> =
      document.querySelectorAll(".highlighted-text");

    Array.from(highlightedElements).forEach((element: HTMLElement) => {
      const textNode: Text = document.createTextNode(element.textContent || "");

      const parentNode = element.parentNode;
      parentNode?.replaceChild(textNode, element);
      parentNode?.normalize();
    });
    resolve();
  });
}
