export const titleMapping: { [key: string]: string } = {
  "": "Article",
  "https://casetext.com/case/meyer-v-uber-techs-inc":
    "Meyer v. Uber Techs., Inc.",
  "https://scholar.google.com/scholar_case?case=16144362801389808371&q=plazza+v+airbnb+inc&hl=en&as_sdt=2006":
    "PLAZZA and Naude v. AIRBNB, INC",
};

// function showDropdown = (
//   x: number,
//   y: number,
//   setDropdownPosition: React.Dispatch<
//     React.SetStateAction<{
//       x: number;
//       y: number;
//     } | null>
//   >
// ) => {
//   setDropdownPosition({ x, y });
// };

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const handleMouseUp = async (
  e: React.MouseEvent,
  setDropdownPosition: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    } | null>
  >
): Promise<void> => {
  await delay(10);
  const selection = window.getSelection();
  const selectedText = selection?.toString();
  if (selectedText) {
    const range = selection?.getRangeAt(0);
    const rect = range?.getBoundingClientRect();
    if (rect) {
      const x: number = (rect.left + rect.right) / 2 - 70;
      const y: number = rect.bottom;
      setDropdownPosition({ x, y });
    }
  } else {
    setDropdownPosition(null);
  }
};

const handleMouseDown = (
  e: React.MouseEvent,
  setDropdownPosition: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    } | null>
  >
): void => {
  e.stopPropagation();
  setDropdownPosition(null);
};

const handleDropdownClick = (
  e: React.MouseEvent,
  setDropdownPosition: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    } | null>
  >
): void => {
  setDropdownPosition(null);
};

const handleDropdownMouseDown = (
  e: React.MouseEvent,
  setSelectedText: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >,
  setDropdownPosition: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    } | null>
  >
): void => {
  const selection = window.getSelection();
  const selectedTxt = selection?.toString();
  if (selectedTxt) {
    navigator.clipboard.writeText(selectedTxt);
    setSelectedText(selectedTxt);
  }
  selection?.removeAllRanges(); // Deselect the text
  setDropdownPosition(null);
};

export {
  handleMouseUp,
  handleMouseDown,
  handleDropdownClick,
  handleDropdownMouseDown,
};
