import React, { useEffect, useRef, useState } from "react";
import { ChatItem } from "./chat-item";
import { highlightText, removeHighlight } from "../helpers/highlight-text";
import { Chat, Highlight, Message } from "../helpers/data-models";
// import { mockChat } from "../helpers/mock-data";
import { url_init_conversation, url_post_message } from "../utils/constants";
import { objectToURLParams } from "../helpers/api-helpers";
import { ChatSendButton } from "./small-components";

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface ChatContainerProps {
  selectedText?: string | null;
  setExampleData: React.Dispatch<React.SetStateAction<string>>;
}

export const ChatContainer = (props: ChatContainerProps) => {
  const [inputValue, setInputValue] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [typing, setTyping] = useState(false);
  const [chatList, setChatList] = useState<Chat[]>([]);
  const turnNumber = useRef(2);
  const [conversationID, setConversationID] = useState<string>("");
  const hasInitialized = useRef(false);

  const handleChatHighlight = (chat: Chat) => {
    // const content = chat?.content as Highlight;
    // if (content?.highlighted_by === "assistant") {
    //   removeHighlight();
    //   highlightText(content.highlight);
    // }
  };

  // const simulateResponse = async () => {
  //   setTyping(true);
  //   try {
  //     let newChats: Chat[] = [];
  //     while (turnNumber.current < mockChat.length) {
  //       const nextChat = mockChat[turnNumber.current % mockChat.length];
  //       if (nextChat.type === "user" || nextChat.type === "discuss") {
  //         turnNumber.current++;
  //         break;
  //       }
  //       if (nextChat.type === "highlight") {
  //         handleChatHighlight(nextChat);
  //       } else {
  //         newChats.push(nextChat);
  //       }

  //       turnNumber.current++;
  //     }
  //     const reversedNewChats = newChats.reverse();
  //     setChatList((prevChatList) => [...reversedNewChats, ...prevChatList]);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  //   setTyping(false);
  // };

  const initializeConversation = async () => {
    try {
      console.log("initializing conversation");
      const data = {
        user_id: "test",
      };
      const urlWithParams = `${url_init_conversation}?${objectToURLParams(
        data
      )}`;
      const response = await fetch(urlWithParams, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response && response.status === 200) {
        const data = await response.json();
        console.log("data", data);
        setConversationID(data.conversation_id);
        console.log("conversation_id", conversationID);
        const messages: Message[] = data.messages;
        setNewMessage(messages[0].content);
        setChatList((prevChatList) => [
          {
            type: "assistant",
            content: messages[0].content,
            location: "chat-start",
            message: messages[0].content,
            status: "Delivered",
          },
          ...prevChatList,
        ]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (!hasInitialized.current) {
      initializeConversation();
      hasInitialized.current = true;
    }

    return () => {
      console.log("ChatContainer unmounted");
    };
  }, []);

  useEffect(() => {
    if (props.selectedText && props.selectedText !== "") {
      addUserMessage(props.selectedText ?? "");
      // simulateResponse();
    }
  }, [props.selectedText]);

  const callPostMessageAPI = async () => {
    setTyping(true);
    try {
      const data = {
        conversation_id: conversationID,
        message: { author: "user", content: inputValue },
      };
      const response = await fetch(url_post_message, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
      });
      if (response && response.status === 200) {
        const data = await response.json();
        console.log("data", data);
        const messages: Message[] = data;
        const message = messages[0];
        setChatList((prevChatList) => [
          {
            type: message.author,
            content: message.content,
            status: "Delivered",
          },
          ...prevChatList,
        ]);
      }
    } catch (error) {
      console.log("error", error);
    }
    setTyping(false);
  };

  const handleSend = async () => {
    if (typing) return;
    addUserMessage(inputValue);
    setInputValue("");
    // simulateResponse();
    callPostMessageAPI();
  };

  const addUserMessage = (inputValue: string) => {
    setChatList((prevChatList) => [
      {
        type: "user",
        content: inputValue,
        status: "Delivered",
      },
      ...prevChatList,
    ]);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="flex flex-col h-[100vh]" id="chat-container">
      <div className="h-full overflow-y-auto ">
        <div
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            flexGrow: 1,
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {typing && (
            <div className="pb-1">
              <ChatItem
                chat={{
                  type: "assistant",
                  content: "...",
                  status: " ",
                }}
                setExampleData={props.setExampleData}
              />
            </div>
          )}
          {chatList.map((chat, index) => (
            <ChatItem
              key={index}
              chat={chat}
              setExampleData={props.setExampleData}
            />
          ))}
        </div>
      </div>
      <div className="pb-2 form-control">
        <div className="input-group">
          <input
            type="text"
            placeholder="Chat…"
            className="w-full outline-none input input-bordered"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSend();
              }
            }}
          />
          <ChatSendButton onClick={handleSend} />
        </div>
      </div>
    </div>
  );
};
