import logo from "./../midpage.png";
import confused from "./../confused.png";
import { highlightText, removeHighlight } from "../helpers/highlight-text";
import { Chat, Suggestion } from "../helpers/data-models";

const logos = {
  assistant: logo,
  user: confused,
};

const chatLocation = {
  assistant: "chat-start",
  user: "chat-end",
};

const names = {
  assistant: "Assistant",
  user: "You",
};

interface ChatItemProps {
  chat: Chat;
  setExampleData: React.Dispatch<React.SetStateAction<string>>;
}

export const ChatItem: React.FC<ChatItemProps> = ({ chat, setExampleData }) => {
  function formatTime(date: Date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }
  return (
    <div className={`chat ${chatLocation[chat.type] ?? "chat-start"}`}>
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          <img title="Hey" src={logos[chat.type ?? "assistant"]} />
        </div>
      </div>
      <div className="chat-header">
        {names[chat.type ?? "assistant"]}
        <time className="pl-1 text-xs opacity-50">
          {chat.time ?? formatTime(new Date())}
        </time>
      </div>
      <div className="chat-bubble text-start">
        {(chat.type == "assistant" || chat.type == "user") &&
          (chat.content as string)}
        {/* {chat.type == "suggestion" && (
          <ChatSuggestion
            suggestion={chat.content as Suggestion}
            setExampleData={setExampleData}
          />
        )} */}
      </div>
      <div className="opacity-50 chat-footer">{chat.status ?? "Delivered"}</div>
    </div>
  );
};

const QuoteDisplay: React.FC<{ quote: string }> = ({ quote }) => {
  return (
    <div className="relative pl-6 mt-2 mb-5">
      <div
        className="absolute top-0 bottom-0 w-1 bg-gray-300 rounded-sm left-1"
        style={{ zIndex: "1" }}
      ></div>
      <p className="">{quote}</p>
    </div>
  );
};

const ChatSuggestion: React.FC<{
  suggestion: Suggestion;
  setExampleData: React.Dispatch<React.SetStateAction<string>>;
}> = ({ suggestion, setExampleData }) => {
  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleSuggestionClick = async (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();
    if (suggestion.document) setExampleData(suggestion.document);
    await delay(500);
    removeHighlight();
    highlightText(suggestion.highlight);
  };
  return (
    <div className="flex flex-col">
      <a
        href={suggestion.document}
        className="underline cursor-pointer"
        onClick={handleSuggestionClick}
      >
        {suggestion.title}
      </a>
      <div>{suggestion.citation}</div>
      <div>
        <span className="font-bold text-gray-400">
          {suggestion.document ? "Summary" : "Explanation"}:
        </span>{" "}
        {suggestion.summary}
      </div>
      <QuoteDisplay quote={suggestion.quote} />
    </div>
  );
};
