import { htmlContent1 } from "../helpers/htmlContent1";
import { htmlContent2 } from "../helpers/htmlContent2";
const htmlMapping: { [key: string]: string } = {
  "https://casetext.com/case/meyer-v-uber-techs-inc": htmlContent1,
  "https://scholar.google.com/scholar_case?case=16144362801389808371&q=plazza+v+airbnb+inc&hl=en&as_sdt=2006":
    htmlContent2,
};

export const ExampleData: React.FC<{ exampleData: string }> = ({
  exampleData,
}) => {
  return exampleData ? (
    <div dangerouslySetInnerHTML={{ __html: htmlMapping[exampleData] }} />
  ) : (
    <div></div>
  );
};
