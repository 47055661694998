import React, { useState } from "react";
import { ExampleData } from "./example-data";
import { ChatContainer } from "./chat-container";
import {
  ColumnContainer,
  CopyDropdown,
  HomePageContainer,
  TabContent,
} from "./home-page-styles";
import {
  handleDropdownClick,
  handleDropdownMouseDown,
  handleMouseDown,
  handleMouseUp,
  titleMapping,
} from "../helpers/homepage-helpers";

export const HomePage = () => {
  const [dropdownPosition, setDropdownPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [selectedText, setSelectedText] = useState<string | null>();
  const [exampleData, setExampleData] = useState<string>("");

  return (
    <HomePageContainer>
      <ColumnContainer
        onMouseUp={(e) => handleMouseUp(e, setDropdownPosition)}
        onMouseDown={(e) => handleMouseDown(e, setDropdownPosition)}
      >
        <div className="box-content h-full border rounded-lg border-base-300">
          <div className="flex flex-row justify-center flex-grow w-full py-2 font-bold border-b-2">
            {titleMapping[exampleData] ?? "Article"}
          </div>
          <TabContent>
            <ExampleData exampleData={exampleData} />
          </TabContent>
        </div>
      </ColumnContainer>
      <ColumnContainer>
        <ChatContainer
          selectedText={selectedText}
          setExampleData={setExampleData}
        />
      </ColumnContainer>
      {dropdownPosition && (
        <CopyDropdown
          style={{
            position: "absolute",
            left: dropdownPosition.x,
            top: dropdownPosition.y,
            zIndex: 1000,
          }}
          onClick={(e) => handleDropdownClick(e, setDropdownPosition)}
          onMouseDown={(e) =>
            handleDropdownMouseDown(e, setSelectedText, setDropdownPosition)
          }
        >
          Discuss with midpage
        </CopyDropdown>
      )}
    </HomePageContainer>
  );
};
