import styled from "styled-components";

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  overflow: hidden;
`;

const ColumnContainer = styled.div`
  flex: 1;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 5px;
`;

const TabContent = styled.div`
  // display: block;
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
`;

const CopyDropdown = styled.div`
  background-color: #2c2c2c;
  border-radius: 4px;
  color: white;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export { HomePageContainer, ColumnContainer, TabContent, CopyDropdown };
