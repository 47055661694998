import "./App.css";
import { HomePage } from "./ui-components/home-page";

function App() {
  return (
    <div className="App" data-theme="cmyk">
      <HomePage />
    </div>
  );
}

export default App;
